<template>
  <div id="app">
    <v-app id="inspire">
      <v-app id="inspire">
        <v-main>
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-card class="elevation-0 login-formMainDiv">
                  <v-card-title class="text-h4 font-weight-bold my-3">Login</v-card-title>
                  <v-card-subtitle class="text-h7 font-weight-medium mb-3">Welcome to Nexus EPS</v-card-subtitle>
                  <!-- <v-toolbar flat>
                    <v-toolbar-title>Login</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar> -->
                  <v-card-text class="pb-0">
                    <v-form>
                      <v-text-field label="Login" name="login" type="text" autofocus outlined dense
                        aria-autocomplete="off" v-model="form.email" :error="errors.email != null"
                        :error-messages="errors.email" @keydown.enter="onSubmit"></v-text-field>

                      <v-text-field id="password" label="Password" name="password"
                        :type="showPassword ? 'text' : 'password'" outlined dense aria-autocomplete="off"
                        v-model="form.password" :error="errors.password != null" :error-messages="errors.password"
                        @keydown.enter="onSubmit" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="px-4">
                    <v-btn :loading="loading" block color="black" class="white--text" @click="onSubmit">Login</v-btn>
                  </v-card-actions>
                  <v-btn text color="grey" to="/forgotPassword">
                    Forgot Password?
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="6" class="loginBgImg">
                <!-- <img src="../../assets/Group.svg" width="100%" /> -->
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
  data: function () {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
      errors: {},
      message: "",
      showPassword: false
    };
  },
  methods: {
    redirect(href) {
      this.$router.push(href);
    },
    onSubmit(event) {
      let _self = this;
      this.loading = true;
      event.preventDefault();
      axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

      this.$axios
        .post("api/login", _self.form)
        .then((res) => {
          let responsedata = res.data;

          if (!responsedata.status) {
            _self.errors = {};
            if (responsedata.errors) {
              for (let i = 0; i < responsedata.errors.length; i++) {
                _self.errors[responsedata.errors[i].field] =
                  responsedata.errors[i].message;
              }
            }
          } else {
            _self.errors = {};
            // Storing token
            localStorage.setItem("user", JSON.stringify(responsedata.data));
            localStorage.setItem("user_token", responsedata.data.token);
            let baseURL =
              responsedata.data.role == "admin"
                ? `${process.env.VUE_APP_API_BASE_URL}/admin`
                : `${process.env.VUE_APP_API_BASE_URL}/api`;
            axios.defaults.baseURL = baseURL;
            axios.defaults.headers.common = {
              Authorization: "Bearer " + responsedata.data.token,
            };
            Vue.prototype.$user = responsedata.data;
            _self.$router.push({ path: "/home" });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.password = "";
    },
  },
};
</script>

<style>
.loginBgImg {
  background-image: url("../../assets/login-v1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.login-formMainDiv {
  width: min(100%, 400px);
  margin-inline: auto;
}
</style>
